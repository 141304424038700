<template>
<div class="container border-container">
    <div class="topBtns " v-if="canEdit">
        <el-button type="primary" icon="el-icon-plus" @click="routerChange(1)" v-if='auth("week.plan.update")'>新增周计划</el-button>
    </div>
    <el-row class="search-container" type="flex" align="middle" justify="space-between">
        <el-col :lg="2" :sm="4" :xs="8">
            <span class="list-title">周计划</span>
        </el-col>
        <el-col :lg="4" :sm="10" :xs="8">
            <el-select class="circular-bead-select input-select" clearable @change="pageChange(1)" v-model="searchForm.month"
                placeholder="选择月份">
                <el-option :label="item.label" :value="item.value" v-for="item of monthList" :key="item.value">
                </el-option>
            </el-select>
        </el-col>
        <el-col :lg="4" :sm="10" :xs="8">
            <el-select clearable @change="pageChange(1)" v-model="searchForm.week" @clear="pageChange(1)"
                placeholder="选择周次" prefix-icon="el-icon-search"
                class="circular-bead-select input-select" popper-class="weekListSelect">
                <el-option v-for="item in weekNumList" :key="item.value" :class="item.created ? 'created' : '' "
                    :label="item.label" :value="item.value">
                    <span>{{ item.value }}</span>
                </el-option>
            </el-select>
        </el-col>
        <el-col :lg="4" :sm="12" :xs="11">
            <el-input clearable @keyup.enter.native="pageChange(1)" class="circular-bead-input" placeholder="请输入名称"
                prefix-icon="el-icon-search" v-model="searchForm.name" @clear="pageChange(1)" title="按回车键搜索">
            </el-input>
        </el-col>
        <el-col :lg="8" :sm="12" :xs="12" >
            <div class="date-picker-group">
                <el-date-picker class="circular-bead-date-picker" v-model="searchForm.beginTime" type="date"
                    value-format="timestamp" @change="pageChange(1)" placeholder="选择开始日期">
                </el-date-picker>
                <div class="split-line">-</div>
                <el-date-picker class="circular-bead-date-picker" v-model="searchForm.endTime" type="date"
                    value-format="timestamp" @change="pageChange(1)" placeholder="选择结束日期">
                </el-date-picker>
            </div>
        </el-col>
    </el-row>
    <div class="table-container">
        <el-table ref="multipleTable" :data="scheduleList" tooltip-effect="dark" style="width: 100%" size="medium"
            @selection-change="handleSelectionChange"
            :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
            :cell-style="{height: '50px',padding: '5px 0',color: '#3F4549'}">
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column prop="name" label="周" min-width="70">
                <template slot-scope="scope">
                    <div class="week-tag">
                        {{'第' + scope.row.week.toString().padStart(2, '0') + '周'}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="时间段" width="250">
                <template slot-scope="scope">
                    <div class="fz-bold hover-text-colourful"
                        @click="routerChange(3, scope.row)" v-if="auth('week.plan.view')">
                        {{scope.row.begin_time | timeFilter(2)}} - {{scope.row.end_time | timeFilter(2)}}
                    </div>
                    <div class="fz-bold" v-else>
                        {{scope.row.begin_time | timeFilter(2)}} - {{scope.row.end_time | timeFilter(2)}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="名称" min-width="140">
                <template slot-scope="scope">
                    <div class="fz-bold hover-text-colourful"
                        @click="routerChange(3, scope.row)" v-if="auth('week.plan.view')">{{scope.row.name}}</div>
                    <div class="fz-bold" v-else>{{scope.row.name}}</div>
                </template>
            </el-table-column>
            <el-table-column label="设计人" min-width="70" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{ designer ? designer : (scope.row.creator ? scope.row.creator.name : '-')}}
                </template>
            </el-table-column>
            <el-table-column label="设计时间" width="160">
                <template slot-scope="scope">
                    {{scope.row.create_time | timeFilter}}
                </template>
            </el-table-column>
            <el-table-column label="操作" :min-width="canEdit ? '180' : '100' " align="center">
                <template slot-scope="scope">
                    <el-button type="text" class="icon-btn" @click="routerChange(2, scope.row)" v-if="canEdit && auth('week.plan.update')">
                        <el-tooltip placement="top" content="编辑">
                            <i class="iconfont icon-edit" />
                        </el-tooltip>
                    </el-button>
                    <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)" v-if="canEdit && auth('week.plan.update')">
                        <el-tooltip placement="top" content="删除">
                            <i class="iconfont icon-delete" />
                        </el-tooltip>
                    </el-button>
                    <el-button type="text" class="icon-btn" @click="routerChange(3, scope.row)" v-if="auth('week.plan.view')">
                        <el-tooltip placement="top" content="查看">
                            <i class="iconfont icon-check" />
                        </el-tooltip>
                    </el-button>
                    <el-button type="text" class="icon-btn" @click="handleDailogShow(scope.row)">
                        <el-tooltip placement="top" content="导出">
                            <i class="iconfont icon-download" />
                        </el-tooltip>
                    </el-button>
                </template>
            </el-table-column>
            <div class="" slot="empty">
                <no-data></no-data>
            </div>
        </el-table>
    </div>
    <div class="page-container">
        <div></div>
        <!-- <el-button round icon="operation-btn el-icon-printer" @click="handlePrinter">打印</el-button> -->
        <el-pagination layout="total, sizes, prev, pager, next" background
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="currentPage"
            :page-sizes="[total, 10, 15, 20, 25]"
            :total="total" :page-size="pageSize">
        </el-pagination>
    </div>
    <el-dialog title="导出课表" class="print-address" width="400px"
        :visible.sync="printDialogShow" close-on-click-modal >
        <div class="dialog-content" v-loading.lock="printLoading">
            <el-button v-if="!printLoading" type="primary" @click="handleOpenUrl" icon='iconfont icon-download' class="download-btn"> 下载</el-button>
        </div>
        <!-- <span slot="footer" class="dialog-footer" style="display:flex;justify-content: center;min-height: 32px;">
        </span> -->
    </el-dialog>
</div>
</template>

<script>
import Core from '@/core';
import dayjs from 'dayjs';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        planType: {
            type: [Number,String],
        },
    },
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,

            planId: 0,
            year: '',
            detail: {},

            total: 0,
            currentPage: 1,
            pageSize: 10,
            scheduleList: [],
            multipleSelection: [],
            monthList: Core.Const.MONTH_LIST,
            searchForm: {
                name: '',
                week: '',
                beginTime: '',
                endTime: '',
                month: ''
            },
            canEdit: 0,
            providerName: Core.Data.getProvider(),

            weekNumList: [],
            ScheduleWeekNum: [],
            printDialogShow: false,
            printAddress: '',
            printLoading: '',
        };
    },
    computed:{
        designer() {
            let designer = this.providerName
            if (this.providerName && this.canEdit) {
                designer = ''
            }
            return designer
        }
    },
    created() {
        this.planId = this.$route.query.plan_id;
        this.year = this.$route.query.year;
        this.getSchedule();
    },
    mounted() {
        const date = new Date();
        this.canEdit = parseInt(this.$route.query.can_edit);
        this.getWeekList();

        console.log('auth("week.plan.update"):', this.auth("week.plan.update"))
    },
    methods: {
        auth: Core.Utils.auth,
        getSchedule() {
            let beginTime = this.searchForm.beginTime ? this.searchForm.beginTime / 1000 : '';
            let endTime = this.searchForm.endTime ? this.searchForm.endTime / 1000 : '';

            let week = /[0-9]+/.exec(this.searchForm.week)
            if (this.searchForm.week === '') {
                week = ''
            } else if (week === null) {
                this.$message.warning('周次信息不正确，请输入正整数')
            } else {
                week = week[0]
            }
            if (this.searchForm.month) {
                this.searchForm.beginTime = ''
                this.searchForm.endTime = ''
                const monthIndex = this.searchForm.month - 1;
                const year = this.year;
                const dayNum = dayjs()
                    .year(year)
                    .month(monthIndex)
                    .daysInMonth();
                beginTime = dayjs()
                    .year(year)
                    .month(monthIndex)
                    .date(1)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .unix();
                endTime = dayjs()
                    .year(year)
                    .month(monthIndex)
                    .date(dayNum)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .unix();
            }
            Core.Api.WeekPlan.list(
                this.currentPage,
                this.searchForm.name,
                "",
                week,
                beginTime,
                endTime,
                this.pageSize,
                this.planId
            ).then(res => {
                console.log('getSchedule res:', res)
                this.total = res.count;
                this.scheduleList = res.list;
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        pageChange(page) {
            this.currentPage = page;
            this.getSchedule();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getSchedule();
        },
        routerChange(key, q = '') {
            console.log("routerChange -> q", q)
            switch (key) {
                case 1: // 新增
                    this.$router.push({
                        path: '/plan/schedule-edit',
                        query: { year: this.year, new_plan: 1, plan_id: this.planId, can_edit:this.canEdit}
                    });
                    break;
                case 2: // 编辑
                    this.$router.push({
                        path: '/plan/schedule-edit',
                        query: { year: q.year, week: q.week, plan_id: this.planId, can_edit:this.canEdit}
                    });
                    break;
                case 3: // 查看
                    this.$router.push({
                        path: '/plan/schedule-edit',
                        query: { year: q.year, week: q.week, edit_lock: 1, plan_id: this.planId, can_edit:this.canEdit}
                    });
                    break;
                case 4: { // 打印
                    let routeUrl = this.$router.resolve({
                        path: '/plan/schedule-print',
                        query: {
                            year: q.year,
                            week: q.week,
                            edit_lock: 1,
                            plan_id: this.planId,
                            can_edit:this.canEdit,
                            show_style: 0,
                            token: Core.Data.getToken(),
                        }
                    })
                    console.log("routerChange -> routeUrl", routeUrl)
                    window.open(routeUrl.href, '_blank')
                }
                    break;
            }
        },
        handleDelete(id) {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    Core.Api.WeekPlan.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.pageChange(1);
                });
            });
        },
        getWeekList() {
            Core.Api.WeekPlan.weekList(
                this.planId,
                this.year,
            ).then(res => {
                console.log('getWeekList res:', res)
                this.ScheduleWeekNum = res.list
                for (let i = 0; i < 52; i++) {
                    let num = i + 1;
                    let created = false;
                    if (this.ScheduleWeekNum.includes(num)) {
                        created = true;
                    }
                    this.weekNumList.push({
                        value: num,
                        label: '第' + num + '周',
                        created,
                    })
                }
            });
        },
        handlePrinter() {
            // this.$message.warning('功能暂未实装')
        },
        handleDailogShow(item){
            this.printDialogShow = true;
            this.printLoading = true;
            // this.routerChange(4, item);
            let host = window.location.hostname;
            let url = 'https://course.school.rebuildsoft.com';
            switch (host) {
                case 'course.school.rebuildsoft.com':
                    url = 'https://course.school.rebuildsoft.com'
                    break;

                case 'course.dev.school.rebuildsoft.com':
                    url = 'https://course.dev.school.rebuildsoft.com'
                    break;

                case 'demo.course.school.rebuildsoft.com':
                    url = 'https://demo.course.school.rebuildsoft.com'
                    break;
            }
            Core.Api.Print.export(
                url,
                item.year,
                item.week,
                item.plan_id,
            ).then(res => {
                this.printAddress = res.url.replace("http:", "https:");
                this.printLoading = false;
            }).catch(err => {
                this.$message.warning('导出失败，请重试')
            })
        },
        handleOpenUrl() {
            // window.open(this.printAddress)
            window.open(this.printAddress,'_blank')
            this.handelDialogClose();
        },
        handelDialogClose() {
            this.printAddress = ''
            this.printDialogShow = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-container {
        .list-title {
            @include title-name(16px, 22px);
        }
        .el-col{
            padding: 10px;
            &:last-child {
                padding-right: 0;
            }
            &:first-child {
                padding-left: 0;
            }
        }
        .input-select,
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner{
            width: 100%;
        }
    }
    .page-container {
        justify-content: space-between;
        .operation-btn {
            i {
                color: rgb(165, 173, 180);
            }
        }
    }
    .print-address {
        .dialog-content {
            height: 50px;
            @include flex(row, center, center);
            .download-btn {
                padding: 5px 36px;
                line-height: 20px;
            }
        }
    }
}
</style>
